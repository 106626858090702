<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo.png"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    RUE EL KOUNAITRA 1-dar Chaabene El Fehri
                  </b-card-text>
                  <b-card-text class="mb-0">
                    gsr-cle1@hotmail.fr
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +216 97 607 740
                  </b-card-text>
                  <b-card-text class="mb-0">
                    MF : 1082793F/A/M/000
                  </b-card-text>
                </div>

                <div class="invoice-number-datedate mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title">
                      Facture N°
                    </h4>
                    <b-input-group
                      class="input-group-merge invoice-edit-input-group disabled"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="generate"
                      />
                    </b-input-group>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title" />
                    <flat-pickr
                      v-model="invoiceData.date"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>
            <hr class="invoice-spacing">
             <b-card-body class="invoice-padding pt-0">
              <b-form-group>
                <div class="d-flex align-items-center mt-3">
                  <label class="mr-1 h4">Société</label>
                  <b-form-radio-group
                    v-model="company"
                  >
                    <b-form-radio
                      value="doorly"
                      class="h3"
                    >
                      Doorly
                    </b-form-radio>
                   <!--  <b-form-radio
                      value="rafika"
                      class="h3"
                    >
                      Rafika Khalfallah
                    </b-form-radio> -->
                  </b-form-radio-group>
                </div>
              </b-form-group>
            </b-card-body>
            <b-card-body class="invoice-padding pt-0">
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Client
                  </h6>
                  <v-select
                    v-model="invoiceData.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customers"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                  />

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.customer"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      Client : {{ invoiceData.customer.company_name }}
                    </h6>
                  </div>
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoiceData.customer = null"
                />
              </b-row>
            </b-card-body>

            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <b-col cols="12">
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix unité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          <label class="d-inline d-lg-none">Labelle</label>
                          <b-form-input
                            v-if="item.product.name == ''"
                            v-model="item.product.name"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.product"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="setSelected(item.product, index)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Prix unité</label>
                          <b-form-input
                            v-model="item.product.sales_price_ttc"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p
                            v-if="item.product.sales_price_ttc"
                            class="mb-1"
                          >
                            {{
                              (item.product.sales_price_ttc * item.qty).toFixed(
                                3
                              )
                            }}
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div></div></b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItem"
              >
                Ajouter produit
              </b-button>
            </b-card-body>

            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                />

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">

                    <hr class="my-50">
                    <div
                      v-if="totalTTC"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        {{ totalTTC }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>
                  </div></b-col></b-row>
            </b-card-body>

            <hr class="invoice-spacing">
          </b-card>
        </b-form>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="save"
          >
            Enregistrer
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  VBToggle,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InvoiceSidebarAddNewCustomer from './CreateCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    vSelect,
    InvoiceSidebarAddNewCustomer,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio, BFormRadioGroup,
    BFormGroup
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      last: [],
      customers: [],
      barcode: '',
      exist: false,
      company: 'doorly',
    }
  },
  computed: {
    generate() {
      return this.last + 1
    },
    totalTTC() {
      return this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.product.sales_price_ttc * item.qty,
        0,
      )
    },

  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getLastInvoice()
    this.getCustomers()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async getLastInvoice() {
      const { data } = await axiosIns.get('/invoices/last/')
      this.last = data
    },
    async getCustomers() {
      const { data } = await axiosIns.get('/customers/')
      this.customers = data
    },

    setSelected(value, index) {
      const f = this.invoiceData.items.filter(item => item.product === value)
      if (f.length > 1) {
        this.removeItem(index)
        // eslint-disable-next-line operator-assignment
        f[0].qty = f[0].qty + 1
      } else {
        f[0].qty = 1
      }
    },

    async refresh() {
      this.exist = false
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },

    withTitle(quantity, index) {
      this.$swal({
        title: 'Stock Alerte',
        icon: 'warning',
        text: `Quantité demandé est superieur au stock       il vous reste que ${quantity} pièce`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    async save() {
      const date = new Date()
      const now = this.formatFn(date.toISOString())
      let customer
      if (this.invoiceData.customer) {
        customer = this.invoiceData.customer.id
      } else {
        customer = null
      }

      const obj = {
        number: this.generate,
        date: this.invoiceData.date,
        customer,
        products: this.invoiceData.items,
        date_today: now,
        timbre: this.invoiceData.timbre,
        company:this.company
      }
      try {
        await axiosIns.post('/invoices/create/', obj)
        this.$router.push({ name: 'invoices' })
        this.showToast('success', 'top-center', 'Facture ajouté avec succés')
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },

    async addNewItem(result) {
      const itemFormBlankItem = {
        product: result,
        qty: 1,
      }
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(itemFormBlankItem)),
      )
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const itemFormBlankItem = {
      product: {},
      qty: 1,
    }
    const orders = ref([])
    const current = new Date()

    const invoiceData = ref({
      id: '',
      customer: null,
      date: `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      note: '',
    })

    const products = ref([])
    axiosIns.get('/products/for-sales/').then(response => {
      products.value = response.data
    })

    return {
      invoiceData,
      products,
      itemFormBlankItem,
      orders,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
